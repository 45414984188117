__webpack_public_path__ = window.__webpack_public_path__;

import mixpanel from 'mixpanel-browser';

const initializeMixpanelInstance = ()=> {
  // TODO: should find better way to handle test/development env without a token
  // since this is still making an API call to Mixpanel
  mixpanel.init(window.__mixpanel_token__ || "TEST_TOKEN", {
    debug: window.__mixpanel_debug__
  });

  window.Mixpanel = mixpanel;
};

const resetMixpanelInstance = ()=> {
  if (window.Mixpanel) {
    Mixpanel.reset();
    Mixpanel._flags.disable_all_events = false;
  }
};

const log = (...args)=> {
  if (window.__mixpanel_debug__) {
    let prefixedArgs = ["Mixpanel:"];
    console.log(...(prefixedArgs.concat(args)));
  }
};

if (!window.Mixpanel) {
  initializeMixpanelInstance();
}

document.addEventListener("turbo:load", ()=> {
  const userDataElement = document.getElementById("user-data-tag");
  const userData = userDataElement ? JSON.parse(userDataElement.textContent) : {};

  if (userData.sign_out_event || userData.impersonate_event) {
    log("Resetting instance");
    resetMixpanelInstance();
  }

  if (userData.impersonating) {
    log("Disabling tracking");
    Mixpanel.disable();
  }

  if (userData.id && userData.sign_in_event && !userData.impersonating) {
    log("Calling #identify");
    Mixpanel.identify(userData.id);
  } else if (userData.impersonating && userData.id) {
    log("Registering user ID and impersonation attributes");
    Mixpanel.register({
      "$user_id": userData.id,
      "distinct_id": userData.id,
      "impersonating": true
    });
  } else if (userData.id && userData.id != Mixpanel.get_distinct_id()) {
    // Runs for a signup event only
    log("Registering user ID");
    Mixpanel.identify(userData.id);

    if (Mixpanel?.cookie?.props) {
      Mixpanel.people.set({
        "utm_source": Mixpanel.cookie.props.utm_source,
        "utm_campaign": Mixpanel.cookie.props.utm_campaign,
        "utm_medium": Mixpanel.cookie.props.utm_medium,
        "utm_content": Mixpanel.cookie.props.utm_content,
        "utm_term": Mixpanel.cookie.props.utm_term
      });
    }
  }

  if (userData.id) {
    Mixpanel.register({
      "user_id": userData.id,
      "user_created_at": userData.created_at,
      "user_trial_until": userData.trial_until,
      "user_trial_window": userData.trial_window,
      "user_confirmed": userData.confirmed
    });
  }

  if (location.pathname == "/subscription/new" && userData.id) {
    Mixpanel.track("visited_subscription_new")
  }

  if (location.pathname == "/account/confirmation/new" && userData.id) {
    Mixpanel.track("visited_confirmation_new")
  }
});
